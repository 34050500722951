import {styled} from "styled-components";
import { BrowserRouter,  Routes, Route, Navigate   } from 'react-router-dom';
import {HashLink as Link} from "react-router-hash-link";

import { FaBars } from 'react-icons/fa';
import img from "./img/Logo.png";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState, useRef } from "react";
import { GoUnmute } from "react-icons/go";
import { GoMute } from "react-icons/go";
import Home from './Home';
import rs from "./img/pngegg.png";
import hu from "./img/pngegghu.png";
import music from "./music.mp3";


const item = {
  exit:{
    opacity: 0,
    height:0,
    transition:{
      ease: "easeInOut",
      duration: 0.3,
      delay: .9
    }
  }
};

function Navbar(){


  const [lang, setLang] = useState("rs");
  
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const options = ['rs', 'hu'];
  

  const selectOption = (option) => {
    setLang(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
    

  const [open, setOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState('');

  useEffect(() => {
    const audio = audioRef.current;

    if (isPlaying) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [isPlaying]);
  
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      const currentScrollPos = window.pageYOffset;

      sections.forEach(section => {
        const sectionTop = section.offsetTop - 150;
        const sectionBottom = sectionTop + section.offsetHeight;

        if (currentScrollPos >= sectionTop && currentScrollPos < sectionBottom) {
          setActiveMenuItem(section.getAttribute('id'));
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleMenuItemClick = (id) => {
    setActiveMenuItem(id);
    const section = document.getElementById(id);
    const sectionTop = section.offsetTop - 20;
    window.scrollTo({ top: sectionTop, behavior: 'smooth' });
  };
  
  const handleButtonClick = (item) => {
    setLang(item);
    setOpen(!open);
};

    return(
        <BrowserRouter>
        <Nav
          initial={{
            y:-200
          }}
          animate={{
            y:0
          }}
          transition={{
            duration: 0.5
          }}
        
        >
    
     
        <Icon href="/#Home">
            <img src={img} alt="logo" />
            <div>
                <h2>Polikarp</h2>
                <p>{lang==="rs"?"salon za masažu":"masszázs szalon"}</p>
            </div>
        </Icon>
        <Bars  onClick={()=>setOpen(!open)}/>
        <AnimatePresence>
          {open?<MenuContainer>
            <motion.div
              variants={item}
              initial={{height:0, opacity:0}}
              animate={{height:"100vh", opacity:1}}
              transition={{duration:.3}}
              exit="exit"
            >
              <div className="btn-close" onClick={()=>setOpen(!open)}
                 initial={{y:80,opacity:0}}
                 animate={{y:0, opacity:1}}
                 transition={{delay:1.3}}
                 exit={{
                  opacity:0,
                  y:90,
                    transition:{
                      ease:"easeInOut",
                      delay:1
                    }
                 }}
              >X</div>
              
                 <motion.button className="music" onClick={handlePlayPause}
                   initial={{y:80,opacity:0}}
                   animate={{y:0, opacity:1}}
                   transition={{delay:1.2}}
                   exit={{
                    opacity:0,
                    y:90,
                      transition:{
                        ease:"easeInOut",
                        delay:1
                      }
                   }}
                 >
                  {isPlaying ? <GoMute /> : <GoUnmute />}
                </motion.button>
              <motion.a 
                  initial={{y:80,opacity:0}}
                  animate={{y:0, opacity:1}}
                  transition={{delay:1.1}}
                  className="rs"
                  exit={{
                   opacity:0,
                   y:90,
                     transition:{
                       ease:"easeInOut",
                       delay:1
                     }
                  }}
                href="#" onClick={()=>handleButtonClick("rs")}>
                  <img src={rs} alt="rs" />
                </motion.a>
                <motion.a 
                  initial={{y:80,x:0,opacity:0}}
                  animate={{y:0,x:60, opacity:1}}
                  transition={{delay:.9}}
                  className="rs"
                  exit={{
                   opacity:0,
                   y:90,
                     transition:{
                       ease:"easeInOut",
                       delay:1
                     }
                  }}
                href="#" onClick={()=>handleButtonClick("hu")}>
                  <img src={hu} alt="hu" />
                </motion.a>
               
              <motion.a 
                  initial={{y:80,opacity:0}}
                  animate={{y:0, opacity:1}}
                  transition={{delay:.8}}
                  exit={{
                   opacity:0,
                   y:90,
                     transition:{
                       ease:"easeInOut",
                       delay:1.1
                     }
                  }}
                href="#Home" onClick={()=>setOpen(!open)}>{(lang==="rs")?"Početna":"Kezdőlap"}</motion.a>
              <motion.a 
                 initial={{y:80,opacity:0}}
                 animate={{y:0, opacity:1}}
                 transition={{delay:.7}}
                 exit={{
                  opacity:0,
                  y:90,
                    transition:{
                      ease:"easeInOut",
                      delay:.8
                    }
                 }}
                href="#About" onClick={()=>setOpen(!open)}>{(lang==="rs")?"O nama":"Rólunk"}</motion.a>
              
              <motion.a 
                initial={{y:80,opacity:0}}
                animate={{y:0, opacity:1}}
                transition={{delay:.6}}
                exit={{
                 opacity:0,
                 y:90,
                   transition:{
                     ease:"easeInOut",
                     delay:.4
                   }
                }}
                href="#Gallery" onClick={()=>setOpen(!open)}>{(lang==="rs")?"Galerija":"Galéria"}</motion.a>
              <motion.a 
                initial={{y:80,opacity:0}}
                animate={{y:0, opacity:1}}
                transition={{delay:.5}}
                exit={{
                 opacity:0,
                 y:90,
                   transition:{
                     ease:"easeInOut",
                     delay:.2
                   }
                }}
                href="#Blog" onClick={()=>setOpen(!open)}>{(lang==="rs")?"Blog o masaži":"Blog"}</motion.a>
              <motion.a 
                 initial={{y:80,opacity:0}}
                 animate={{y:0, opacity:1}}
                 transition={{delay:.4}}
                 exit={{
                  opacity:0,
                  y:90,
                    transition:{
                      ease:"easeInOut",
                      delay:.1
                    }
                 }}
                href="#Contact" onClick={()=>setOpen(!open)}>{(lang==="rs")?"Kontakt":"Elérhetőségek"}</motion.a>
             
            </motion.div>
          </MenuContainer>:""}
        </AnimatePresence>
        <NavMenu>
      <audio ref={audioRef} loop>
        <source src={music} type="audio/mp3" />
            
      </audio>
          <NavLink to='/#Home' className={activeMenuItem === 'Home' ? 'active' : ''} onClick={() => handleMenuItemClick('Home')}> 
            {lang==="rs"?"Početna":"Kezdőlap"}
          </NavLink>
          <NavLink to='/#About' className={activeMenuItem === 'About' ? 'active' : ''} onClick={() => handleMenuItemClick('About')}>
          {lang==="rs"?"O nama ":"Rólunk"}
          </NavLink>
          <NavLink to='/#Gallery' className={activeMenuItem === 'Gallery' ? 'active' : ''} onClick={() => handleMenuItemClick('Gallery')}>
          {lang==="rs"?"Galerija":"Galéria"}
          </NavLink>
          <NavLink to='/#Blog' className={activeMenuItem === 'Blog' ? 'active' : ''} onClick={() => handleMenuItemClick('Blog')}>
          {lang==="rs"?"Blog o masaži":"Blog"}
          </NavLink>
          <NavLink to='/#Contact' className={activeMenuItem === 'Contact' ? 'active' : ''} onClick={() => handleMenuItemClick('Contact')}  >
          {lang==="rs"?"Kontakt":"Elérhetőségek"}
          </NavLink>
          <div onClick={()=>setLang("rs")}>
            <img src={rs} alt="rs" />
          </div>
          <div onClick={()=>setLang("hu")}>
            <img src={hu} alt="hu" />
          </div>
          <div>
          <button className="music" onClick={handlePlayPause}>
            {isPlaying ? <GoMute /> : <GoUnmute />}
          </button>
          </div>
        </NavMenu>
      </Nav>
        {<Routes>
            <Route path="/" element={ <Home language={lang}/> } />
            <Route path="/redirect" element={ <Home /> } />
        </Routes>
    }
         </BrowserRouter>
    )
}

export default Navbar;



const Nav = styled(motion.nav)`
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 85px;
  display: flex;
  justify-content: space-between;
  
  z-index: 12;
  //background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2.6px);
  -webkit-backdrop-filter: blur(2.6px);
  background: rgba(255, 253, 253, 0.75);
  //backdrop-filter: saturate(180%) blur(10px);
  /* Third Nav */
  /* justify-content: flex-start; */
  .music{
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 42px;
    height: 40px;
    border: solid 1px #bdbdbd;
    color: #182a6c;;
    background: #fff;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
  }
  .music:hover{
    background: #182a6c;
    color: #fff;
    transition: all 0.2s ease-in;
  }
`;
const Dropdown = styled.div`
  position: relative;
  display: inline-block;


.dropdown-toggle {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown-menu li {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}
`
const NavLink = styled(Link)`
  color: #7ACEE8;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  padding: 0 25px;
  font-weight: 500;
  height: 100%;
  cursor: pointer;
  &:hover {
    color: #182a6c;
    transition: all .3s ease-in-out;
  }
  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #182a6c;
    transform: scaleX(0);
    transition: transform 1s ease;
  }
  &:hover::before {
    transform: scaleX(1);
  }
  &.active {
    color: #182a6c;
  }
`;
  
const Bars = styled(FaBars)`
  display: none;
  color: #7ACEE8;
  
  @media screen and (max-width: 1024px) {
    display: flex;
    align-items: center;
    cursor: pointer;  
    width: 30px;
    margin: 15px;
    height: 30px;
  }
`;

const Icon = styled.a`
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    margin: 35px;
    img{
        width: 60px;
        height: auto;
    }
    div{
        margin-left: 5%;
        display: flex;
        flex-direction: column;
        line-height: 15px;
        h2{
            font-family: 'Croissant One', cursive;
            margin: 0;
            padding: 0;
            color: #182a6c;
            font-size: 30px;
        }
        p{  margin-top: 6px;
            //margin: 0;
            padding: 0;
            color:#7ACEE8;
            font-size: 15px;
        }
    }
    @media screen and (max-width: 280px) {
      img{
        width: 40px;
      }
        div{
          display: none;
        }
    }
`
  
const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  div{
    
    img{
      width: 50px;
      cursor: pointer;
    }
  }
 
 
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 200vh;
  background: #fff;
  color: #1a1a1a;
  div{
    display: flex;
    position: absolute;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
  div a{
    color: #182a6c;;
    font-weight: 500;
    display: inline-flex;
    text-decoration: none;
    height: 50px;
    overflow: hidden;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 2vw;
  }
  .btn-close{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 58px;
    height: 58px;
    font-weight: 500;
    font-size: 20px;
    border: solid 1px #bdbdbd;
    color: #182a6c;;
    background: #fff;
    border-radius: 58px;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    cursor: pointer;
    font-size: 18px;
  }
  .rs{
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .hu{
    position: absolute;
    top: 20px;
    left: 70px;
  }
  .image{
      width: 60px;
        height: auto;
  }
`
  

  
