import styled from "styled-components";
import first from './img/1.jpg';
import second from './img/2.jpg';
import third from './img/3.jpg';
import fourth from './img/4.jpg';
import fifth from './img/5.jpg';
import sixth from './img/6.jpg';
import seventh from './img/7.jpg';
import eight from './img/8.jpg';
import nineth from './img/9.jpg';
import tenth from './img/10.jpg';
import eleventh from './img/back1.jpg';
import twenteenth from './img/back2.jpg';
import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useRef } from "react";
import { useInView } from "framer-motion";
import plant from "./img/abc.png";


function Blog ({lg}){

    const[card, setCard] = useState([]);

    useEffect(()=>{
        getData();
        
    },[])

    const getData = async ()=>{
        const api = await fetch('https://polikarphornet.000webhostapp.com/card.php');
        const data = await api.json();
        setCard(data);
        console.log(data);
    }

    let data = [
        {
            id:1,
            imgSrc: first,
            title: "Relaxation Massage",
            text: "cmdscvmowe"
        },
        {
            id:2,
            imgSrc: second,
            title: "Aromatherapy",
            text: "cmdscvmowe"
        },
        {
            id:3,
            imgSrc: third,
            title: "Antistress Massage",
            text: "cmdscvmowe"
        },
        {
            id:4,
            imgSrc: fourth,
            title: "Medicine Massage",
            text: "cmdscvmowe"
        },
        {
            id:5,
            imgSrc: fifth,
            title: "Sport Massage",
            text: "cmdscvmowe"
        },
        {
            id:6,
            imgSrc: sixth,
            title: "Anti-Cellulit Massage",
            text: "cmdscvmowe"
        },
        {
            id:7,
            imgSrc: seventh,
            title: "Facial Massage",
            text: "cmdscvmowe"
        },
        {
            id:8,
            imgSrc: eight,
            title: "Chocolate Massage",
            text: "cmdscvmowe"
        },
        {
            id:9,
            imgSrc: nineth,
            title: "Gold Massage",
            text: "cmdscvmowe"
        },
        {
            id:10,
            imgSrc: tenth,
            title: "Bounty Massage",
            text: "cmdscvmowe"
        },
        {
            id:11,
            imgSrc: eleventh,
            title: "Sole Massage",
            text: "cmdscvmowe"
        },
        {
            id:12,
            imgSrc: twenteenth,
            title: "Prenatal Massage",
            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa iure laboriosam qui blanditiis, veritatis debitis modi quidem consectetur animi amet fugit asperiores ea earum nihil nulla commodi rem, facilis numquam!"
        }
    ]

    const [active, setActive] = useState(11);
   
    const ref = useRef(null);
    const isInView = useInView(ref);

    return(
        <Container id="Blog" ref={ref}
        style={{   backgroundImage: `url(${plant})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',}}
        >
            <motion.h1
                initial={{
                    x: "25vw"
                }}
            // This is working
                animate={
                    isInView?{
                    x: 0,
                    transition: {
                        duration: 0.8,
                        type: "spring",
                        stiffness: 50
                    }
                }:{}}
            
            >{(lg==="rs")?"Blog o masaži":"Blog"}</motion.h1>
            <Contant>
                <Left>
                    <AnimatePresence mode="wait">
                    {Object.values(card).map(item=>{
                        if(item.id=== active){
                            return(
                                <div key={item.id}>
                                    <Img
                                      initial={{opacity:0, scale:0}}
                                      animate={{opacity:1, scale: 1}}
                                      exit={{opacity:0}}
                                      transition={{
                                          duration:1
                                      }}  
                                    ><img src={"https://polikarphornet.000webhostapp.com/"+item.img} alt={item.id} /></Img>
                                    <motion.h2
                                        initial={{opacity:0,}}
                                        animate={{opacity:1}}
                                        exit={{opacity:0}}
                                        transition={{
                                            duration:3
                                        }}
                                    >{(lg==="rs")?item.title:item.cim}</motion.h2>
                                    <motion.p
                                        initial={{opacity:0}}
                                        animate={{opacity:1 }}
                                        exit={{opacity:0}}
                                        transition={{
                                            duration:3
                                        }}
                                    >{(lg==="rs")?item.text:item.szoveg}</motion.p>
                                </div>
                            )
                        }
                        return null;
                    })}
                    </AnimatePresence>
                </Left>
                <Right>
                    <motion.h2
                         initial={{
                            opacity:0,
                            scale:0
                       }}
                       // This is working
                        animate={
                           isInView?{
                           opacity: 1,
                           scale: [0.7,1,1.5,0.7,1],
                           transition: {
                               duration: 3,
                               type: "spring",
                               stiffness: 50
                           }
                       }:{}}
                    
                    >{(lg==="rs")?"Masaži":"Masszázs fajták"}</motion.h2>
                    <ul>
                    {Object.values(card).map(item=>{
                        return(
                         <motion.li key={item.id} className={active===item.id?"active":""} onClick={()=>{setActive(item.id)}}
                         initial={{
                            opacity:0,
                            scale:0
                       }}
                       // This is working
                        animate={
                           isInView?{
                           opacity: 1,
                           scale: [0.7,1,1.5,0.7,1],
                           transition: {
                               duration: 3,
                               delay:0.3,
                               type: "spring",
                               stiffness: 50
                           }
                       }:{}}
                    
                    >{(lg==="rs")?item.title:item.cim}</motion.li>
                    )})}
                    </ul>
                </Right>
            </Contant>
        </Container>
    )
}

export default Blog;


const Container= styled(motion.section)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    margin: auto;
    margin-bottom: 10px;
    h1{
        text-transform: uppercase;
        color: #182a6c;
        font-size: 40px;
        font-family: 'Croissant One', cursive;
        font-weight: 500;
        text-decoration: underline;
        margin-top: 100px;

    }
    .active{
        color:#182a6c;
    }
`
const Contant= styled(motion.div)`
    display: flex;
    flex-direction: row;
    flex: 2 1;
    width: 80%;
    height: 70vh;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2.6px);
    -webkit-backdrop-filter: blur(2.6px);
    border-radius: 10px;
    @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        width: 95%;
    }
`
const Left = styled.div`
    width: calc(100% / 3 * 2);
    display: flex;
    flex-direction: column;
    img{
        width: 100%;
        padding: 3px;
        max-height: 400px;
    }
    h2{
        text-align: left;
        font-size: 25px;
        margin-left: 50px;
        color: #182a6c;
        font-family: 'Croissant One', cursive;
    }
    p{
        margin: auto 50px;
        color: #4a4a4a;
    }
    @media screen and (max-width: 1024px) {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            margin-right: 10px;
            p{
            margin: 20px 10%;
            font-size: 20px;
            line-height: 35px;
        }
    }
`
const Img = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin:2% auto ;
    width: 100%;
    @media screen and (max-width: 1024px) {
        //width: 80%;
       //margin:2% auto ;
    }
`
const Right = styled(motion.div)`
    width: calc(100% / 3 * 1);
    display: flex;
    flex-direction: column;
    background: #7ACEE8;
    color: #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    h2{
        margin-left: 10%;
        margin-right: 10%;
        text-align: left;
        margin-top: 15%;
        color: #182a6c;
        text-transform: uppercase;
        font-size: 25px;
        font-family: 'Croissant One', cursive;
        font-weight: 400;
        border-bottom:1px solid #182a6c;
    }
    ul{
        margin-top: 15%;
        list-style: none;
        text-align: left;
        font-size: 18px;
        li{
            margin-top: 4%;
            margin-left: 0;
            padding-left: 0;
            text-align: left;
            cursor: pointer;
            font-weight: 600;
        }
        li:hover{
            color: #182a6c;
            transition: color 0.3s ease;
        }
        li:last-child{
            margin-bottom: 4%;
        }
    }
    @media screen and (max-width: 1024px) {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 0px;
    }
`