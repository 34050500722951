import styled from "styled-components";
import picture from "./img/4.jpg";
import { useRef } from "react";
import { motion, useInView } from "framer-motion";

function Gift ({lg}){

    const ref2 = useRef(null);
    const isInView6 = useInView(ref2, {threshold: 0.1});

    return(
        <Container ref={ref2}>
        <Contant>
            <motion.img 
             initial={{
                opacity: 0,
                x:"-25vw"
            }}
            animate={
                isInView6?{
                opacity: 1,
                x:0,
                transition: {
                    duration: 0.8,
                    type: "spring",
                    stiffness: 50
                }
            }:{}} 
            src={picture} 
            alt="picture"/>
        </Contant>
        <Contant>
            <motion.h2
                 initial={{
                    opacity: 0,
                    x:"25vw"
                }}
                animate={
                    isInView6?{
                    opacity: 1,
                    x:0,
                    transition: {
                        duration: 0.8,
                        type: "spring",
                        stiffness: 50
                    }
                }:{}}     
            >{(lg==="rs")?"Poklon Vaučer":"Ajándékutalvány"}</motion.h2>
            <motion.p
             initial={{
                opacity: 0,
                x:"25vw"
            }}
            animate={
                isInView6?{
                opacity: 1,
                x:0,
                transition: {
                    duration: 0.8,
                    type: "spring",
                    stiffness: 50
                }
            }:{}} 
            >
            {(lg==="rs")?"Ako tražite savršen poklon za voljenu osobu, na pravm ste mestu. Priušti svojoj dragoj osobi poklon vaučer sa nekim od naših tretmana koji će sigurno ispuniti vaša očekivanja.":"Ha a tökéletes ajándékot keresi szeretteinek, akkor jó helyen jár. Kényeztesse meg szeretteit egy ajándékutalvánnyal kezeléseink egyikével, amely biztosan megfelel az Ön elvárásainak."}
            </motion.p>
        </Contant>

        </Container>
    )
}

export default Gift;

const Container = styled.div`
    width: 100%;
    min-height: 50vh;
    background: #182a6c;
    display: flex;
    flex-direction: row;
    img{
        width: 30vw;
        margin: 15px;
        border-radius: 10px;
    }
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        justify-content:center;
        align-items: center;
        img{
            width:85vw;
        }
    }
`

const Contant = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2{
        color: #fff;
        font-family: 'Croissant One', cursive;
        font-size: 50px;
        margin-bottom: 40px;
        text-align: left;
        margin-left: 15px;
        margin-right: 100px;
    }
   
    p{
        color: #e3e3e3;
        font-size: 20px;
        margin-left: 15px;
        margin-right: 100px;
        font-weight: 350;
        margin-bottom: 15px;
    } 
    @media screen and (max-width: 1024px) {
        width: 100%;
        img{
            margin-left: 0;
        }
        h2{
            text-align: center;
            margin:0;
            font-size: 35px;
        }    
        p{
            margin: 20px 10%;
            font-size: 20px;
            line-height: 35px;
        }
    }
`