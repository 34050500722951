import './App.css';
import { useState, useEffect } from 'react';
import Navbar from './Navbar';
import styled from 'styled-components';
import { motion } from 'framer-motion';

function App() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setOpen(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);


 


    if(open){
      return(
        <div className="App">
          <Navbar />
        </div>
      )
    }else{
      return(
         <Container
          initial={{
            scale:0
          }}
          animate={{
            scale: 1
          }}
          transition={{
            duration: 3
          }}
         
         >
            <h2>Polikarp</h2>
            <p>salon za masažu</p>
         </Container>
      )
     
    }
}




const Container = styled(motion.div)`
  max-width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  h2{
    font-family: 'Croissant One', cursive;
    margin: 0;
    padding: 0;
    font-size: 200px;
    color: #182a6c;
  }
  p{
    margin: 0 ;
    padding: 0;
    color:#7ACEE8;
    font-size: 80px;
    }
    @media screen and (max-width: 1300px) {
      h2{
        font-size: 70px;
      }
      p{
        font-size: 40px;
      }
    }  
    @media screen and (max-width: 850px) {
      h2{
        font-size: 50px;
      }
      p{
        font-size: 20px;
      }
    }
    
  `

export default App;
