import styled from 'styled-components'
import first from './img/1.jpg';
import second from './img/2.jpg';
import third from './img/3.jpg';
import fourth from './img/4.jpg';
import fifth from './img/5.jpg';
import sixth from './img/6.jpg';
import seventh from './img/7.jpg';
import eight from './img/8.jpg';
import nineth from './img/9.jpg';
import tenth from './img/10.jpg';
import { useEffect, useRef, useState } from 'react';
import {AiOutlineArrowRight, AiOutlineArrowLeft} from "react-icons/ai"
import {RiCloseLine} from "react-icons/ri";
import { AnimatePresence, motion, useInView } from 'framer-motion';
import plant from "./img/abc.png";




function Gallery ({lg}){

    const ref = useRef(null);
    const isInView = useInView(ref);

    const[imgs, setImgs] = useState([]);

    useEffect(()=>{
        getLink();
    },[])

    const getLink = async ()=>{
        const api = await fetch('https://polikarphornet.000webhostapp.com/data.php');
        const data = await api.json();
        setImgs(data);
    }
   


    /*let data = [
        {
            id:1,
            imgSrc: first
        },
        {
            id:2,
            imgSrc: second
        },
        {
            id:3,
            imgSrc: third
        },
        {
            id:4,
            imgSrc: fourth
        },
        {
            id:5,
            imgSrc: fifth
        },
        {
            id:6,
            imgSrc: sixth
        },
        {
            id:7,
            imgSrc: seventh
        },
        {
            id:8,
            imgSrc: eight
        },
        {
            id:9,
            imgSrc: nineth
        },
        {
            id:10,
            imgSrc: tenth
        }
    ]*/

 

    const [model, setModel] = useState(false);
    const [id , setId]= useState("");
    const [tempingSrc, setTempImgSrc] = useState('');
    const getImg= (id)=>{
        setId(id);
        Object.values(imgs).map(item=>{
            if(item.index===id){
                setTempImgSrc(item.src);
              
            }
            return   setModel(true);
        })
        //setTempImgSrc(imgSrc);
        //(true);
    }
    //const nextImg(id, )

    const nextImg=()=>{
        let num =0;
        Object.values(imgs).map(item=>{
            if(item.src===tempingSrc){
                num= item.index;
                //setTempImgSrc(item.imgSrc)
            } 
            return  getImg(num+1);
        })
      
    }
    const prewImg=()=>{
        let num =0;
        Object.values(imgs).map(item=>{
            if(item.src===tempingSrc){
                num= item.index;
                //setTempImgSrc(item.imgSrc);
            }
             return getImg(num-1);
        })
       
    }

    return(
        <Container id="Gallery"  ref={ref}
        style={{   backgroundImage: `url(${plant})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',}}
        >
      
    <motion.h2
                initial={{
                    x: "25vw"
                }}
                animate={
                    isInView?{
                    x: 0,
                    transition: {
                        duration: 0.8,
                        type: "spring",
                        stiffness: 50
                    }
                }:{}}
                  /*key={isInView}
                  initial={{x:"25vw"}}
                  animate={{x:0}}
                  exit={{scale:1,opacity:0}}
                  transition={{
                    duration: 0.8,
                    type: "spring",
                    stiffness: 50
                  }}*/
                 /* style={{
                    transform: isInView ? "none" : "translateX(1200px)",
                    opacity: isInView ? 1 : 0,
                    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s"
                }}*/
            >{(lg==="rs")?"Galerija":"Galéria"}</motion.h2>
        <Contant >
                
          
            <div className={model?"model open": "model"}>
            <AnimatePresence mode="wait" initial={false}>
                    <motion.img src={"https://polikarphornet.000webhostapp.com/"+tempingSrc} 
                        initial={{ x:100,opacity: 0 }}
                        animate={{ x:0,opacity: 1 }}
                        key={"id"+id+tempingSrc}
                        exit={{ x: -100, opacity:0}}
                        transition={{
                            duration: 1.2,
                            type: "spring",
                            stiffness: 50
                        }}
                    /></AnimatePresence>
                    <RiCloseLine id="x" onClick={()=> setModel(false)}/>
                    {
                        Object.values(imgs).map(item=>{
                            if(item.src===tempingSrc && item.index<(Object.keys(imgs).length)){
                                return(
                                   <AiOutlineArrowRight id="right"  onClick={()=>{nextImg()}}/>
                                   )
                            }return null;
                        })
                    }
                    {   Object.values(imgs).map(item=>{
                            if(item.src===tempingSrc && item.index>1){
                                return(
                                    <AiOutlineArrowLeft id="left" onClick={()=>{prewImg()}}/>
                                )
                            }
                            return null;
                        })
                    }
                    
              
            </div>  
            <div>
                {  Object.values(imgs).map(item=>{
                    console.log(item.src)
                    return(
                        <motion.img key={item.src} 
                                    src={"https://polikarphornet.000webhostapp.com/"+item.src} 
                                    alt={item.index}  
                                    className="opened"
                                    onClick={()=>{getImg(item.index)}}
                                    initial={{opacity:0}}
                                    animate={  isInView?{opacity:1,
                                        transition:{
                                              duration:0.5,
                                        delay: item.index*0.1
                                        }
                                    
                                    }:""}
                                    whileHover={{scale:1.1,
                                        transition:{
                                            delay:0,
                                            duration:0.5
                                        }
                                    }}
    
                        />
                    )
                })}
            </div>
            </Contant>
           
        </Container>
    )
}

export default Gallery;

const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    //width: 80%;
    margin: auto;
    h2{
        text-transform: uppercase;
        color: #182a6c;
        font-size: 40px;
        font-family: 'Croissant One', cursive;
        font-weight: 500;
        text-decoration: underline;
        margin-top: 100px;
        margin-bottom: 30px;
    }
    div{
        
        img{
            width: 300px;
            height: 300px;
            margin: 8px;
            cursor: pointer;
            border-radius: 10px;
        }
    }
    .model{
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #151515;
        transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.5s ease-in-out;
        visibility: hidden;
        opacity: 0;
        transform: scale(0);
        z-index: 999;
    }
    .model.open{
        visibility: visible;
        opacity: 1;
        transform: scale(1);
        img{
            width: 85%;
            height: auto;
        }
        #x{
            position: fixed;
            top: 10px;
            right: 10px;
            height:  50px;
            width: 50px;
            padding: 5px;
            color: #fff;
            cursor: pointer;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.1);
           // z-index: 999;
        }
        #right{
            position: fixed;
            top: 50%;
            right: 10px;
            height:  50px;
            width: 50px;
            padding: 5px;
            color: #fff;
            cursor: pointer;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.1);
           // z-index: 999;
        }
        #left{
            position: fixed;
            top: 50%;
            left: 10px;
            height:  50px;
            width: 50px;
            padding: 5px;
            color: #fff;
            cursor: pointer;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.1);
           // z-index: 999
        }
    }
`
const Contant = styled(motion.div)` 
    div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    margin: 0;
    overflow:hidden;
`
