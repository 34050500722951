import styled from "styled-components";
import { useRef } from "react";
import { useInView } from "framer-motion";
import plant from "./img/abc.png";
import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";

function Contact ({lg}){

    const ref = useRef(null);
    const isInView = useInView(ref);

    return(
        <Container id="Contact" ref={ref}
        style={{   backgroundImage: `url(${plant})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',}}
        >
            <Contant  
                
               style={{
                transform: isInView ? "none" : "translateX(-1200px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s"
            }}
               >
            <Map>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2780.8280084720814!2d19.633339176181913!3d45.814702809984524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4744a9876757870f%3A0xe88ee73dd26f8ae!2sOmladinska%203%2C%20Ba%C4%8Dka%20Topola%2024300!5e0!3m2!1shu!2srs!4v1695799738891!5m2!1shu!2srs" width="100%" height="500" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map159"></iframe>
            </Map>
            <Form>
               <h1>{(lg==="rs")?"Kontaktne informacije":"Elérhetőségek"}</h1>
               <p className="info">
                <a href="https://www.facebook.com/milovanovic.dijana.90"><FaFacebook /></a>
                <a href="https://www.instagram.com/salon_za_masazu_polikarp/"><AiFillInstagram /></a>
               </p>
               <p>
                <i>Telefon:</i><br/>
                +381 61 1742139
                </p>
                <p>
                <i>{(lg==="rs")?"Adresa:":"Cím:"}</i><br/>
                 Omladinska 3, Backa Topola, Serbia
                 </p>
                 <p>
                     <i>Email:</i><br/>
                     milovanovicd91@gmail.com
                 </p>
                 <p>
                     <i>{(lg==="rs")?"Radno vreme:":"Munkaidő:"}</i> <br/>
                 </p>
                <Row>
             
                    <Col>
                    <p>{(lg==="rs")?"Ponedeljak:":"Hétfő"}</p>
                    <p>{(lg==="rs")?"Utorak:":"Kedd:"} </p>
                    <p>{(lg==="rs")?"Sreda:":"Szerda"}</p>
                    <p>{(lg==="rs")?"Četvrtak:":"Csütörtök"}</p>
                    <p>{(lg==="rs")?"Petak:":"Péntek"}</p>
                    <p>{(lg==="rs")?"Subota:":"Szombat"}</p>
                    <p>{(lg==="rs")?"Nedelja:":"Vasránap"}</p>
                    </Col> 
                    <Col>
                        <p>15:00</p>
                        <p>09:00</p>
                        <p>15:00</p>
                        <p>15:00</p>
                        <p>15:00</p>
                        <p>09:00</p>
                        <p>{(lg==="rs")?"Zatvoreno":"Zárva"}</p>
                    </Col>
                    <Col>
                    <p>21:00</p>
                    <p>15:00</p>
                    <p>21:00</p>
                    <p>21:00</p>
                    <p>21:00</p>
                    <p>15:00</p>
                    <p></p>
                    </Col>
                   

                    
                </Row>
                    
              
              
            </Form>
            </Contant>
            <Footer>©2023 Created by <Link to="https://github.com/VirtualHornet?tab=repositories">Attila Celluska </Link></Footer>
        </Container>
    )
}

const Container = styled.section`
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 10px;
    
    .info{
        text-align: center;
        a{
            margin-left: 1px;
            margin-right: 25px;
            font-size: 35px;
            color:#182a6c;
       
        }
        a:hover{
             color: #4a4a4a;
             transition:color 0.3s ease-in-out;
        }
    }
   
`
const Col = styled.div`
    display:flex;
    flex-direction: column;
    margin-left: 10%;
    @media screen and (max-width: 1024px) {
        margin: 5px;
    }
  
`
const Row = styled.div`
    display:flex;
    flex: 1 1 1 ;
    width: 100%;
    flex-direction: row;
`
const Contant = styled.div`
      display: flex;
        width: 80%;
        flex-direction: row;
        flex: 1 1;
        margin: auto;
        padding: 10px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 0px 10px 0px #666;
        @media screen and (max-width: 1024px) {
            flex-direction: column;
            width: 95%;
        }    
`

const Map = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex: 50%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    @media screen and (max-width: 1024px) {
       //width: 10%;
    }
`

const Form = styled.form`
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 10px;
    background: #fff;
    text-align: center;
    h1{
        margin-bottom: 10px;
        color: #18456c;
    }
    p{
        text-align: left;
        margin-left: 10%;
        font-size: 20px;
        color: #4a4a4a;
    }
    i{
        color: #18456c;
        line-height: 30px;
        font-weight: 500;
    }
    @media screen and (max-width: 1024px) {
        p{
            margin-left: 0;
        }
    }
`

const Footer = styled.div`
    display: flex;
    bottom: 5px;
    color: #343434;
    margin-top: 10px;
    a{
        text-decoration: none;
        color: #343434;
        padding-left: 5px;
    }
    @media only screen and (max-width: 912px) {
        font-size: 11px;
    }

`


export default Contact;

