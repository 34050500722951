import styled from "styled-components"
import Me from "./img/9.jpg";
import plant from "./img/abc.png";
import { motion, AnimatePresence, useInView } from "framer-motion";
import { useRef } from "react";
import { useState, useEffect } from "react";

function About ({lg}){

   
    const ref = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);

    const isInView = useInView(ref, {treshold: 0.5});
    const isInView3 = useInView(ref3 ,{treshold: 0.5});

    const isInView6 = useInView(ref2, {treshold: 0.9});
    

    const[card, setCard] = useState([]);

    useEffect(()=>{
        getData();
    },[])

    const getData = async ()=>{
        const api = await fetch('https://polikarphornet.000webhostapp.com/card.php');
        const data = await api.json();
        setCard(data);
        //console.log(data);
    }
   
  

    
        return(
            <Container id="About" style={{   backgroundImage: `url(${plant})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',}}>  
            <AnimatePresence mode="wait" initial={false}>
        
            <Contant>
            <Main ref={ref}>
            <motion.div 
                initial={{
                    x: "-15vw", opacity:0
                }}
                animate={
                    isInView?{
                    x: 0,
                    opacity:1,
                    transition: {
                        duration: 0.8,
                        type: "spring",
                        stiffness: 50
                    }
                }:{}}>
                <img src={Me} alt="me" />
            </motion.div>   
            </Main>
            <div>
                <motion.h2
                        initial={{
                            opacity: 0,
                            scale:0
                        }}
                        animate={
                            isInView?{
                            opacity: 1,
                            scale:1,
                            transition: {
                                duration: 0.8,
                                type: "spring",
                                stiffness: 50
                            }
                        }:{}} 
                        ref={ref3}
                        >   
                {(lg==="rs")?"O NAMA":"RÓLUNK"}</motion.h2>
                <motion.p
                        initial={{
                            x: "25vw",
                            opacity:0
                        }}
                        animate={
                            isInView3?{
                            opacity: 1,
                            x:0,
                            transition: {
                                duration: 0.8,
                                type: "spring",
                                stiffness: 50
                            }
                        }:{}} 
                     
                >{(lg==="rs")?
                "Pružamo uslugu masaže koristeći kombinaciju efikasnih tehnika, kako bi došli do najboljih rezultata. Trudimo se da se osećate prijatno i opušteno uz prilagodjeno iskustvo masaže. Vaš terapeut će raditi sa Vama na rešavanju problematičnih područja ipružanju ublažavanja stresa i bola, kao i povećanju pokretljivosti i cirkulacije.":"Masszázs szolgáltatást nyújtunk hatékony technikák kombinációjával a legjobb eredmény elérése érdekében. Arra törekszünk, hogy egy személyre szabott masszázsélménnyel kényelmesen és nyugodtan érezze magát. A terapeuta együttműködik Önnel a problémás területek kezelésében, stressz- és fájdalomcsillapításban, valamint fokozott mobilitásban és keringésben."}
                </motion.p>
                {(lg==="rs")?(<motion.p
                    initial={{
                        x: "25vw",
                        opacity:0
                    }}
                    animate={
                        isInView3?{
                        opacity: 1,
                        x:0,
                        transition: {
                            duration: 0.8,
                            type: "spring",
                            stiffness: 50
                        }
                    }:{}} 
                >Zakažite termin <a href='/#Contact'>ovde</a></motion.p>):(<motion.p
                    initial={{
                        x: "25vw",
                        opacity:0
                    }}
                    animate={
                        isInView3?{
                        opacity: 1,
                        x:0,
                        transition: {
                            duration: 0.8,
                            type: "spring",
                            stiffness: 50
                        }
                    }:{}} 
                >Foglaljon <a href='/#Contact'>itt</a> időpontot</motion.p>)}
                <motion.h3 
                        initial={{
                            opacity: 0,
                            x:"-25vw"
                        }}
                        animate={
                            isInView6?{
                            opacity: 1,
                            x:0,
                            transition: {
                                duration: 0.8,
                                type: "spring",
                                stiffness: 50
                            }
                        }:{}}         
                >{(lg==="rs")?"Cenovnik usluga":"Szolgálatások ára"}</motion.h3>

                <CardContainer ref={ref2} >
                {Object.values(card).map(item=>{
                    return(
                  
                    <Card
                            initial={{
                                opacity: 0
                            }}
                           
                            animate={
                                isInView6?{
                                opacity: 1,
                                transition: {
                                    duration: 0.5,
                                    delay: item.id*0.1,
                                    type: "spring",
                                    stiffness: 50
                                }
                            }:{}}        

                         
                    >  <a href="/#Blog" key={item.id} >    
                        <img src={"https://polikarphornet.000webhostapp.com/"+item.img} alt={item.id} />
                       
                        {(lg==="rs")?(<p>{item.title}</p>):(<p>{item.cim}</p>)}
                         
                        <i>{item.price} din</i> </a>
                    </Card>
                   
                 )})}
                    </CardContainer>
                 
                </div>
          
            </Contant>
            </AnimatePresence>
        </Container>)}


export default About;

const Container= styled(motion.section)`
   
    img{
        width: 350px;
        height: 350px;
        border-radius: 10px;
        margin-top: 100px;
        border: 1px solid #4a4a4a;;
    }
    h2{
        text-transform: uppercase;
        color: #182a6c;
        font-size: 40px;
        font-family: 'Croissant One', cursive;
        font-weight: 500;
        margin-bottom: 30px;
        text-align: center;
        margin-top: 40px;
        padding-top: 40px;
        border-top: 1px solid #182a6c;
        margin-left: 40% ;
        margin-right: 40% ;
        @media screen and (max-width: 1024px) {
            margin-left: auto;
            margin-right: auto;
            width: 80%;
        }
    }
    h3{
        text-transform: uppercase;
        color: #182a6c;
        font-size: 30px;
        font-family: 'Croissant One', cursive;
        font-weight: 500;
        margin-bottom: 30px;
        text-align: center;
        margin-top: 40px;
        padding-top: 40px;
        border-top: 1px solid #182a6c;
        margin-left: 40% ;
        margin-right: 40% ;
        @media screen and (max-width: 1024px) {
            margin-left: auto;
            margin-right: auto;
            width: 80%;
        }
    }
    a{
        color: #182a6c
    }
    p{
        color: #4a4a4a;
        margin: auto 20%;
        line-height: 30px;
    }
`
const Contant= styled.div`
    margin-top: 150px;
         width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: auto; 
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2.6px);
    -webkit-backdrop-filter: blur(2.6px);
    @media screen and (max-width: 1300px) {
        width: 95%;
        p{
            margin: auto 10%;
            font-size: 20px;
            line-height: 35px;
        }
    }
    
`
const Main = styled.div`
    
`  

const CardContainer = styled(motion.div)`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
`
const Card = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 20%;
    min-height: 40vh;
    margin: 10px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2.6px);
    -webkit-backdrop-filter: blur(2.6px);
    border-radius: 10px;
    text-align: center;
    a{
        text-decoration: none;
    }
    p{
        font-size: 23px;
        line-height: 20px;
    }
  
    img{
        width: 200px;
        height: 200px;
        margin: 10px;
        padding: 0px;
    }
    i{
        margin-bottom: 5px;
    }
    &:hover{
        scale: 1.1;
        transition: scale 0.3s ease-in-out;
    }
    @media screen and (max-width: 1024px) {
        &:hover{
            scale: 1;
        }   
        width: 90%;
        img{
            width:250px;
            height: 250px;
        }
        i{
            font-size: 20px;
        }
    }
`