import { useEffect, useState , useCallback, useRef} from "react";
import back1 from "./img/back2.jpg";
import back2 from "./img/back6.jpg";
import back3 from "./img/back4.jpg";
import mob1 from "./img/mob.png";
import mob2 from "./img/mob2.png";
import mob3 from "./img/mob3.png";

import About from "./About";
import Blog from "./Blog";
import Gallery from "./Gallery";
import Contact from "./Contact";
import Gift from "./Gift";
import { AnimatePresence, motion } from "framer-motion";

import styled from "styled-components"

function Home ({ language }){

   
    const windowWidth = useRef(window.innerWidth);
    const [tempingSrc, setTempImgSrc] = useState((windowWidth.current>720)?back1:mob1);
    const [tempText1, setTempText1] = useState((language==="rs")?"Zdravlje &":"Kikapcsolódás &");
    const [tempText2, setTempText2] = useState((language==="rs")?"Opuštanje":"Egészség");

    const [num, setNum] = useState(1);
    const [isInView1, setIsInView1] = useState(false);

   
    
       

    const getImg = useCallback((id) => { 
        let data = [
        {
            id:1,
            imgSrc: (windowWidth.current>720)?back1:mob1,
            text1:(language==="rs")?"Zdravlje &":"Kikapcsolódás &",
            text2: (language==="rs")?"Opuštanje":"Egészség"
        },
        {
            id:2,
            imgSrc: (windowWidth.current>720)?back2:mob2,
            text1: (language==="rs")?"Nega &":"Hozzáértés &",
            text2: (language==="rs")?"Profesionalnost":"Gondoskodás"
        },
        {
            id:3,
            imgSrc: (windowWidth.current>720)?back3:mob3,
            text1: (language==="rs")?"Za muškarce i":"Nőknek és",
            text2: (language==="rs")?"Za žene":"Férfiaknak"
        }
    ]
    data.map(item => {
          if (item.id === id) {
            setTempImgSrc(item.imgSrc);
            setTempText1(item.text1);
            setTempText2(item.text2);
            return true;
          } else {
            return false;
          }
        });
      });
      
      useEffect(() => { 
        
        const timer = setInterval(() => {
         setNum((prevCount) => (prevCount<=6)?prevCount + 1:prevCount);
          if (num % 3 === 2) {
            getImg(1);
          } else if (num % 3 === 1) {
            getImg(2);
          } else{
            getImg(3);
          }
          console.log(tempingSrc);
          console.log("num " + num);
        }, 5000);
      
        return () => clearTimeout(timer);
      }, [tempingSrc,num,getImg, language]);
    
            return(<>
            <Container 
                whileInView={() => {
                    // when element in viewport , set IsInView true!
                    setIsInView1(true);
                    return {};
                }}
                        id="Home" 
                    ><AnimatePresence mode="wait" initial={false}>
            
                <Contant  key={[tempingSrc,isInView1]}>
                <motion.img src={tempingSrc} 
                        key={num}
                        initial={{scale:1,opacity: 0 }}
                        animate={{scale:1.1,opacity: 1}}
                        exit={{scale:1,opacity:0}}
                        transition={{
                            duration: 2.5
                        }}
                        
                    />
                    <motion.h3
                    key={"H3 key "+num}
                    initial={{opacity:0, y:-20}}
                    animate={{opacity:1, y:0}}
                    exit={{opacity:0}}
                    transition={{
                        duration: 1.5
                    }}
                >{language==="rs"?"Dobrodošli u salon za masažu":"Üdvözöljük "}</motion.h3> 
                <motion.h1
                      key={"H1 key "+num}
                      initial={{opacity:0 }}
                      animate={{opacity:1}}
                      exit={{opacity:0}}
                      transition={{
                          duration: 1.5
                      }}
                >Polikarp</motion.h1>
                <motion.h2
                    key={"H2 key" +num}
                    initial={{opacity:0, y:-80}}
                    animate={{opacity:1, y:-70}}
                    exit={{opacity:0}}
                    transition={{
                        duration:1.5
                    }}
                >{tempText1}</motion.h2>
                <motion.h2
                    key={"h2 key"+ num}
                    initial={{opacity:0,y:20}}
                    animate={{opacity:1,y:0}}
                    exit={{opacity:0}}
                    transition={{
                        duration:1.5
                }}>{tempText2}</motion.h2> 
                <Buttons>
                        <motion.a href="/#Contact"
                            key={"btn1" + num}
                            initial={{opacity:0,y:20}}
                            animate={{opacity:1,y:0}}
                            exit={{opacity:0}}
                            transition={{
                                duration:1.5
                            }}
                        ><button className="blue">{(language==="rs"?"Kontakt":"Info")}</button>  </motion.a>
                        <motion.a href="/#Blog"
                            key={"btn2" + num}
                            initial={{opacity:0,y:20}}
                            animate={{opacity:1,y:0}}
                            exit={{opacity:0}}
                            transition={{
                                    duration:1.5
                                }}
                        ><button>{(language==="rs")?"Blog o masaži":"Blog"}</button> </motion.a> 
                </Buttons> 
             
               
                </Contant>
             </AnimatePresence>      
            </Container>
            <About lg={language}/>
            <Gift lg={language}/>
            <Gallery lg={language}/>
            <Blog lg={language}/>
            <Contact lg={language}/> 
            </>
            )
    
           
        
      

}





const Contant = styled(motion.div)` 
    margin: 0;
    overflow:hidden;
    h1{     
            margin-left: 30px;
            color: #182a6c;
            font-size: 90px;
            font-weight: 600;
            position: absolute;
            top:40%;
            transform: translate(0%, -70%);
            font-family: 'Croissant One', cursive;   
           
    }  
    h2{     
            margin-left: 30px;
            color: #7ACEE8;
            font-size: 60px;
            font-weight: 600;
            position: absolute;
            top:50%;
            transform: translateY(0%, -70%);
            font-family: 'Croissant One', cursive;   
           
    }  
    h3{     
        
            margin-left: 30px;
            color: #182a6c;
            font-size: 30px;
            font-weight: 500;
            position: absolute;
            top:20%;
            transform: translate(0%, -50%);
            //font-family: 'Croissant One', cursive;
            
    }  
    img{
            position: relative;
            top:0;
            left: 0;
            width: 100%;
            height: 90vh;
            margin: 0 auto;
            z-index: -1;
            @media screen and (max-width: 1024px) {
                aspect-ratio: 1/2048 1/2048 ;
            }
        }
    @media screen and (max-width: 1024px) {
        h1{
            font-size: 50px;
           
           
                background: rgba(255, 255, 255, 0.18);
                border-radius: 16px;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(0.6px);
                -webkit-backdrop-filter: blur(0.6px);
                border: 1px solid rgba(255, 255, 255, 0.51);
        }
        h2{
            font-size: 35px;
            top:55%;
            transform: translateY("-40px");
            background: rgba(255, 255, 255, 0.18);
                border-radius: 16px;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(0.6px);
                -webkit-backdrop-filter: blur(0.6px);
                border: 1px solid rgba(255, 255, 255, 0.51);
        }
        h3{
            text-align: center;
            font-size: 25px;
            background: rgba(255, 255, 255, 0.18);
                border-radius: 15px;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(0.6px);
                -webkit-backdrop-filter: blur(0.6px);
                border: 1px solid rgba(255, 255, 255, 0.51);
        }
    }
    @media screen and (max-width: 450px) {
      h2{
        font-size: 30px;
      }
      h3{
        font-size: 25px;
      }
    }      
`

const Container = styled(motion.section)`   
       overflow-x: hidden;
       width: 100vw;
       margin-top: 85px;
       //background: red;
`
const Buttons = styled.div`
    display: flex;
    flex-direction:row ;
    width: 50%;
    position: absolute;
    top:70%;
    left: 30px;
   
  
    button{
        padding: 15px;
        font-size: 20px;
        border-radius: 15px;
        cursor: pointer;
        color: #49657E;
        border: 1px solid #49657E;
        margin-right: 35px;
        scroll-behavior: smooth;
        @media screen and (max-width: 1024px) {
            width: 100px;
        }
    }
    button:hover{
        color: #fff;
        background: #182a6c;
        transition: all ease-in-out 0.3s;
    }
    .blue{
        color: #fff;
        background: #182a6c;
    }
    .blue:hover{
        color: #182a6c;
        background: #fff;
        transition: all ease-in-out 0.3s;
    }
    
`

export default Home;